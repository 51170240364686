@import '../../../assets/styles/mixins.scss';

.topbar {
  background: transparent;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .topbar {
    background: transparent;
    padding: 0 rem(20);
    min-height: 125px;
    height: 125px;
    border-bottom: 1px solid $gray-border;
    color: $text;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}