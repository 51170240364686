// @import '~bootstrap-css-only/css/bootstrap.min.css';
// @import 'assets/styles/Antd/antd.cleanui';
// // @import 'assets/styles/CleanUI/cleanui';
// // @import 'assets/styles/Bootstrap/bootstrap.cleanui';
// // @import 'assets/styles/Chartist/chartist.cleanui';
// // @import 'assets/styles/Nprogress/nprogress.cleanui';
// // @import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
// // @import '~c3/c3.min.css';
.text-primary{
  color : slategrey !important;
}

@media screen and (max-width: 800px) {
  .ant-layout-header {
    height : 125px !important;
  }
}

.ant-card {
  box-shadow:0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)!important;
  border-radius: 15px!important;
  .ant-card-head{
    background:#50305a;
    color:#fff;
    border-radius: 15px 15px 0 0!important;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.25rem;	
    @media screen and (max-width: 700px) {
      font-weight: 400;
      letter-spacing: 0.7px;
      font-size: 1rem;	
    }
    .ant-card-head-title{
        padding:14px 0px;
    }
    .ant-btn{
      background: transparent;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
      border: 1px solid white;
      @media screen and (max-width: 700px) {
        font-weight: 400;
        font-size: 1rem;	
      }
   
    }
  }
}

.ant-btn-primary {
  color: #fff;
  background: #50305a;
  border-color: #50305a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:not(.ant-btn-icon-only) {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #50305a;
  border-color: #50305a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.flex-flow-column{
  flex-flow:column !important; ;
}

.highlight {
  background-color: #f2f4f8;
}
.utils__content {
  max-width: 100%;
}
.site-card-wrapper {
  background: #ececec;
  padding: 30px;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  background:transparent !important;
}
.ant-menu-submenu-popup {
.ant-menu-sub{
background:#50305a !important;
.ant-menu-item {
  a{
  color: rgba(255,255,255,0.8) !important;
}
&.ant-menu-item-active{
  a{
    color: rgba(255,255,255,1) !important;
  }
}
  
}

}
}
body .ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background:rgba(116, 187, 66, 0.5) !important;
}

.ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    width: 120px;
  }
.ant-form-item-label{
  label{
font-weight: 600;
  }
}
  

.disabled-form{
  .ant-form-item-control-input-content{
    .ant-input[disabled]{
      color:rgba(0, 0, 0, 0.6);
    }
  }
}


.form-item-flex-col{
  .ant-form-item-control{
    width: 100%;
    max-width: 100%;
  }
  .ant-form-item-control-input-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    .ant-upload-picture-card-wrapper{
      display:flex;
      justify-content:center;
    }
  }
}

.form-full-width-field{
  width:100%;
  .ant-form-item-control{
    width:100%;
    max-width: 100%;
  }
}

.customizeInput{
  .ant-input-affix-wrapper {
    border: 0px !important;
}
}
.cardBox {
  border-radius: 5!important;
  padding: 10!important;
  text-align: center;
  width: 100%;
  // min-height: 150px;
  // max-height: 150px;
  background-color: mediumpurple;
  color: mediumpurple;
}
.cardHead {
  min-height: 50px ;
}
.goog-te-gadget {
  font-family: arial !important;
  font-size: 0px !important;
  color: #666;
  white-space: nowrap;
  width: 0 !important;
  margin-top: 6rem !important;
  margin-left: 0 !important;
}

@media(max-width : 600px) {
  .goog-te-gadget {
    margin-bottom: 70px !important;
  }
  .goog-te-combo {
    width : 92px !important;
  }
}

.goog-logo-link {
  display: none;
}