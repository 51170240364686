// Import mixins
@import '../../../../assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN LAYOUT */

.ant-layout {
  min-height: 100vh;
  background: transparent;
}

.ant-layout-header {
  padding: 0;
  background: transparent;
}

.ant-layout-footer {
  padding: 0;
  background: transparent;
}

.ant-collapse {
  background: transparent;
}

.ant-layout-sider {
  transition: all 0.2s;
}
.ant-layout-sider,.ant-menu.ant-menu-dark{
  // background: #59B6AD ;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color:#9b4716;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background-color:#9b4716;
}

.ant-layout-sider-trigger{
  background-color:#9b4716;
}

.style_icon__3JNKS{
color:#fff;
}